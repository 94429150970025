import SinotransRegistrationFormProperties from './SinotransRegistrationFormProperties'
import SinotransRegistrationApiUrls from 'src/apiUrls/SinotransRegistrationApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngAddForm }
} = components

function SinotransRegistrationAddForm({
    history,
    showNotification,
    onPostSubmitSuccess
}) {
    return (
        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <SinotransRegistrationFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={SinotransRegistrationFormProperties.formikProps}
            toClientDataFormat={SinotransRegistrationFormProperties.toClientDataFormat}
            toServerDataFormat={SinotransRegistrationFormProperties.toServerDataFormat}
            create={{
                url: SinotransRegistrationApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default SinotransRegistrationAddForm
