import {

    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Menu,
    MenuItem,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core'

import MoreIcon from '@material-ui/icons/MoreVert'
import RemoveIcon from '@material-ui/icons/Clear'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useEffect } from 'react'
import { components, useServices } from 'cng-web-lib'
import TransactionLogActionMenu from '../components/TransactionLogActionMenu'
import DocumentInfoDecoratorComponent from '../../common/ui/DocumentInfoDecoratorComponent'
import TransactionLogDecoratorComponet from '../components/TransactionLogDecoratorComponet'


import Paper from '@material-ui/core/Paper';



const {
    form: {
        adapter: {
            useFormAdapter:{ useField, useFormikContext }
        },
        field: {
            CngTextField,
            CngDocumentAutocompleteField

        },
    },
} = components

const TransactionLogComponents = (props) => {
    const { createRecord, fetchRecords } = useServices()
    const { submitForm, setFieldValue } = useFormikContext()

    const [anchorMoreActionEl, setAnchorMoreActionEl] = React.useState(null)
    const open = Boolean(anchorMoreActionEl)

    const handleMoreActionClick = (event) => {
        console.log(event)
        setAnchorMoreActionEl(event.currentTarget)
    }

    const handleMoreActionClose = () => {
        setAnchorMoreActionEl(null)
    }

    if (props.tile == null || props.tile.length <= 0 || props.tile.fileContent == null) {
        return (
            < TableContainer component={Paper} >
                <Table id="table">
                    <TableBody>
                        <TableRow>
                            <TableCell > <FileCopyIcon /></TableCell>
                            <TableCell>File Not Found</TableCell>
                        </TableRow>
                    </TableBody >
                </Table>
            </TableContainer >
        )
    } else {
        var fileName = (props.tile.fileName != null && props.tile.fileName != "") ? props.tile.fileName : props.tile.id;
        return (
            < TableContainer component={Paper} >
                <Table id="table">
                    <TableBody>
                        <TableRow>
                            <TableCell > <FileCopyIcon /></TableCell>
                            <TableCell>
                                <TransactionLogDecoratorComponet name={fileName} /> </TableCell>
                            <TableCell align={'right'}>
                                <TransactionLogActionMenu id={props.tile.id}
                                    downloadRowClick={props.downloadRowClick}
                                /></TableCell>
                        </TableRow>
                    </TableBody >
                </Table>
            </TableContainer >
        )
    }

}

export default TransactionLogComponents
