import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import PortMappingViewForm from './PortMappingViewForm'
import PortMappingAddForm from './PortMappingAddForm'
import PortMappingEditForm from './PortMappingEditForm'
import Namespace from 'src/constants/locale/Namespace'
import PortMappingKeys from 'src/constants/locale/key/PortMapping'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [portMappingId, setPortMappingId] = useState('')
  const { translate } = useTranslation([
    Namespace.PORT_MAPPING,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let portMapping = translate(
      Namespace.PORT_MAPPING,
      PortMappingKeys.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: portMapping
      }
    )

    return {
      portMapping,
      tabLockedMessage
    }
  }

  function makePortMappingForm() {
    if (portMappingId === '') {
      return (
        <PortMappingAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setPortMappingId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <PortMappingEditForm
          showNotification={showNotification}
          id={portMappingId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <PortMappingViewForm
              showNotification={showNotification}
              id={portMappingId}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.portMapping,
              tabContent: makePortMappingForm()
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage