import PortMappingFormProperties from './PortMappingFormProperties'
import PortMappingApiUrls from 'src/apiUrls/PortMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngViewForm }
} = components

function PortMappingViewForm({
    showNotification,
    id,
    onFetchPreSuccess = () => { 
        //do nothing
    }
}) {
    return (
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <PortMappingFormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                />
            }
            formikProps={PortMappingFormProperties.formikProps}
            toClientDataFormat={PortMappingFormProperties.toClientDataFormat}
            toServerDataFormat={PortMappingFormProperties.toServerDataFormat}
            fetch={{
                id: id,
                url: PortMappingApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
        />
    )
}

export default PortMappingViewForm
