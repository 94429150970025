import TransactionLogApiUrls from 'src/apiUrls/TransactionLogApiUrls'
import TransactionLogFormProperties from './TransactionLogFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngViewForm }
} = components

function TransactionLogViewForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     },
    items
}) {
    return (
        <CngViewForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            bodySection={
                <TransactionLogFormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                    items={items}
                />
            }
            formikProps={TransactionLogFormProperties.formikProps}
            toClientDataFormat={TransactionLogFormProperties.toClientDataFormat}
            toServerDataFormat={TransactionLogFormProperties.toServerDataFormat}
            fetch={{
                id: id,
                url: TransactionLogApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
        />
    )
}

export default TransactionLogViewForm
