import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import SinotransRegistrationViewForm from './SinotransRegistrationViewForm'
import SinotransRegistrationAddForm from './SinotransRegistrationAddForm'
import SinotransRegistrationEditForm from './SinotransRegistrationEditForm'
import Namespace from 'src/constants/locale/Namespace'
import SinotransRegistrationKeys from 'src/constants/locale/key/SinotransRegistration'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [sinotransRegistrationId, setSinotransRegistrationId] = useState('')
  const { translate } = useTranslation([
    Namespace.SINOTRANS_REGISTRATION,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let sinotransRegistration = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: sinotransRegistration
      }
    )

    return {
      sinotransRegistration,
      tabLockedMessage
    }
  }

  function makeSinotransRegistrationForm() {
    if (sinotransRegistrationId === '') {
      return (
        <SinotransRegistrationAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setSinotransRegistrationId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <SinotransRegistrationEditForm
          showNotification={showNotification}
          id={sinotransRegistrationId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <SinotransRegistrationViewForm
              showNotification={showNotification}
              id={sinotransRegistrationId}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.sinotransRegistration,
              tabContent: makeSinotransRegistrationForm()
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage