import TransactionLogApiUrls from 'src/apiUrls/TransactionLogApiUrls'
import TransactionLogFormProperties from './TransactionLogFormProperties'
import React, { useState } from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngEditForm }
} = components


function TransactionLogEditForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     },
    items
}) {
    return (
        <CngEditForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            showNotification={showNotification}
            bodySection={
                <TransactionLogFormProperties.Fields
                    disabled={false}
                    showNotification={showNotification}
                    isEdit={true}
                    items={items}
                />
            }
            formikProps={TransactionLogFormProperties.formikProps}
            toClientDataFormat={TransactionLogFormProperties.toClientDataFormat}
            toServerDataFormat={TransactionLogFormProperties.toServerDataFormat}
            fetch={{
                url: TransactionLogApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
            update={{
                url: TransactionLogApiUrls.PUT
            }}
            id={id}
        />
    )
}

export default TransactionLogEditForm
