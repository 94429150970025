import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import SinotransRegistrationApiUrls from 'src/apiUrls/SinotransRegistrationApiUrls'
import React from 'react'
import SinotransRegistrationKeys from 'src/constants/locale/key/SinotransRegistration'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup,
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const fetchCustomLookup = useFetchCustomLookup();

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SINOTRANS_REGISTRATION
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let sinotransRegistration = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: sinotransRegistration
      }
    )
    let shipperPartyId = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.SHIPPER_PARTY_ID
    )
    let userCode = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.USER_CODE
    )
    let companyName = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COMPANY_NAME
    )
    let country = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COUNTRY
    )
    let titleName = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TITLE_NAME
    )
    let companyAddress = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COMPANY_ADDRESS
    )
    let firstName = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.FIRST_NAME
    )
    let lastName = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.LAST_NAME
    )
    let tel = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TEL
    )
    let email = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.EMAIL
    )
    let postcode = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.POSTCODE
    )
    let companyRole = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COMPANY_ROLE
    )
    let uscc = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.USCC
    )
    let industry = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.INDUSTRY
    )
    let productInterest = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.PRODUCT_INTEREST
    )
    let status = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.STATUS
    )
    let remark = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.REMARK
    )

    return {
      sinotransRegistration,
      tableTitle,
      shipperPartyId,
      userCode,
      companyName,
      country,
      titleName,
      companyAddress,
      firstName,
      lastName,
      tel,
      email,
      postcode,
      companyRole,
      uscc,
      industry,
      productInterest,
      status,
      remark
    }
  }

  const columns = [
    {
      field: "shipperPartyId",
      title: translatedTextsObject.shipperPartyId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          {},
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      },
    },
    {
      field: "userCode",
      title: translatedTextsObject.userCode,
    },
    {
      field: "uscc",
      title: translatedTextsObject.uscc,
    },
    {
      field: "status",
      title: translatedTextsObject.status,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              // addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: SinotransRegistrationApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: SinotransRegistrationApiUrls.EXPORT }}
              fetch={{ url: SinotransRegistrationApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
