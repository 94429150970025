import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import NbsMappingKeys from 'src/constants/locale/key/NbsMapping'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: 0,
  chargeCode: "",
  type: "",
  account: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.NBS_MAPPING)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let nbsMapping = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.TITLE
    )
    let partyId = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.PARTY_ID
    )
    let chargeCode = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.CHARGE_CODE
    )
    let type = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.TYPE
    )
    let account = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.ACCOUNT
    )

    return {
      nbsMapping,
      partyId,
      chargeCode,
      type,
      account
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.nbsMapping} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
            <CngLookupAutocompleteField
             name='partyId'
             label={translatedTextsObject.partyId + " *"}
             disabled={disabled}
             lookupProps={{
                url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                label: 'name',
                value: 'id'
            }}
        />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.chargeCode}>
            <CngTextField
              name="chargeCode"
              label={translatedTextsObject.chargeCode}
              disabled={disabled}
             
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.type}>
            <CngTextField
              name="type"
              label={translatedTextsObject.type}
           //   disabled={disabled}
              helperText="C-container level, T-transaction level"
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.account}>
            <CngTextField
              name="account"
              label={translatedTextsObject.account}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
