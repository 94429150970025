import ShippingInstructionRequestApiUrls from 'src/apiUrls/ShippingInstructionRequestApiUrls'
import ShippingInstructionRequestFormProperties from './ShippingInstructionRequestFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
    form: { CngAddForm }
} = components

function ShippingInstructionRequestAddForm({ history, showNotification, onPostSubmitSuccess }) {
    return (
        <CngAddForm
            fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
            history={history}
            showNotification={showNotification}
            bodySection={
                <ShippingInstructionRequestFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={ShippingInstructionRequestFormProperties.formikProps}
            toClientDataFormat={ShippingInstructionRequestFormProperties.toClientDataFormat}
            toServerDataFormat={ShippingInstructionRequestFormProperties.toServerDataFormat}
            create={{
                url: ShippingInstructionRequestApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default ShippingInstructionRequestAddForm
