import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    shipperPartyId: Yup.string().required(requiredMessage),
    userCode: Yup.string().required(requiredMessage),
    companyName: Yup.string(),
    country: Yup.string(),
    title: Yup.string(),
    companyAddress: Yup.string(),
    firstName: Yup.string(),
    lastName: Yup.string(),
    tel: Yup.string(),
    email: Yup.string(),
    postcode: Yup.string(),
    companyRole: Yup.string(),
    uscc: Yup.string(),
    industry: Yup.string(),
    productInterest: Yup.string(),
    status: Yup.string(),
    remark: Yup.string(),
  })
}

export default makeValidationSchema
