import PortMappingFormProperties from './PortMappingFormProperties'
import PortMappingApiUrls from 'src/apiUrls/PortMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngAddForm }
} = components

function PortMappingAddForm({
    history,
    showNotification,
    onPostSubmitSuccess
}) {
    return (
        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <PortMappingFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={PortMappingFormProperties.formikProps}
            toClientDataFormat={PortMappingFormProperties.toClientDataFormat}
            toServerDataFormat={PortMappingFormProperties.toServerDataFormat}
            create={{
                url: PortMappingApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default PortMappingAddForm
