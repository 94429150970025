import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ShippingInstructionRequestKeys from 'src/constants/locale/key/ShippingInstructionRequest'
import { Grid } from '@material-ui/core'
import {
    components,
    DataFlattener,
    DateTimeFormatter,
} from 'cng-web-lib'
import makeValidationSchema from './ShippingInstructionRequestMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import moment from 'moment'

const {
    form: {
        field: {
            CngTextField,
            CngDateTimeField,
        },
    },
    CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
    documentName: "",
    messageId: "",
    senderId: "",
    recipientId: "",
    sentDate: "",
    sentTime: "",
    messageFunction: "",
    orderType: "",
    nbrOfConstsToStuff: "",
    nbrOfPreloadContsToExport: "",
    shipperReference: "",
    bookingReference: "",
    shipperPartycode: "",
    shipperPartyname: "",
    shipperAddressLine: "",
    shipperContactPersonName: "",
    shipperEmailAddress: "",
    carrierPartycode: "",
    carrierPartyname: "",
    consigneePartyname: "",
    consigneeAddressLine: "",
    notifypartyPartyname: "",
    stuffingDate: "",
    stuffingLocation: "",
    vesselName: "",
    voyageNbr: "",
    eta: "",
    etd: "",
    placeOfReceipt: "",
    portOfLoading: "",
    portOfDischarge: "",
    placeOfDelivery: "",
    receiptStatus: "",
    deliveryStatus: "",
    itemLineNbr: "",
    itemCode: "",
    itemDescription: "",
    grosswtValue: "",
    grosswtUnitcode: "",
    grossVolumeValue: "",
    grossVolumeUnitcode: "",
    typeOfPkg: "",
    totalNbrOfPkgs: "",
    markings: "",
    fileType: "",
    source: "",
    recordStatus: "",
    processedDate: "",
    party: "",
    cargoDeliveryDate: "",
    cargoReadyDate: "",
    originReceipt: "",
    destinationReceipt: ""
})

const FORMIK_PROPS = {
    initialValues: { ...DEFAULT_INITIAL_VALUES },
    makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
    const { translate } = useTranslation(Namespace.SHIPPING_INSTRUCTION_REQUEST)
    const translatedTextsObject = makeTranslatedTextsObject()

    function makeTranslatedTextsObject() {
        let shippingInstructionRequest = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.TITLE
        )
        let id = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ID
        )
        let documentName = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.DOCUMENT_NAME
        )
        let messageId = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.MESSAGE_ID
        )
        let senderId = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SENDERID
        )
        let recipientId = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.RECIPIENTID
        )
        let sentDate = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SENTDATE
        )
        let sentTime = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SENTTIME
        )
        let messageFunction = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.MESSAGE_FUNCTION
        )
        let orderType = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ORDER_TYPE
        )
        let nbrOfConstsToStuff = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.NBR_OF_CONSTS_TO_STUFF
        )
        let nbrOfPreloadContsToExport = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.NBR_OF_PRELOAD_CONTS_TO_EXPORT
        )
        let shipperReference = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SHIPPER_REFERNCE
        )
        let bookingReference = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.BOOKING_REFERNCE
        )
        let shipperPartycode = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SHIPPER_PARTYCODE
        )
        let shipperPartyname = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SHIPPER_PARTYNAME
        )
        let shipperAddressLine = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SHIPPER_ADDRESS_LINE
        )
        let shipperContactPersonName = translate(
                    Namespace.SHIPPING_INSTRUCTION_REQUEST,
                    ShippingInstructionRequestKeys.SHIPPER_CONTACT_PERSON_NAME
        )
        let shipperEmailAddress = translate(
                            Namespace.SHIPPING_INSTRUCTION_REQUEST,
                            ShippingInstructionRequestKeys.SHIPPER_EMAIL_ADDRESS
        )
        let carrierPartycode = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.CARRIER_PARTYCODE
        )
        let carrierPartyname = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.CARRIER_PARTYNAME
        )
        let consigneePartyname = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.CONSIGNEE_PARTYNAME
        )
        let consigneeAddressLine = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.CONSIGNEE_ADDRESS_LINE
        )
        let notifypartyPartyname = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.NOTIFYPARTY_PARTYNAME
        )
        let stuffingDate = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.STUFFING_DATE
        )
        let stuffingLocation = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.STUFFING_LOCATION
        )
        let vesselName = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.VESSEL_NAME
        )
        let voyageNbr = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.VOYAGE_NBR
        )
        let eta = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ETA
        )
        let etd = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ETD
        )
        let placeOfReceipt = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.PLACE_OF_RECEIPT
        )
        let portOfLoading = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.PORT_OF_LOADING
        )
        let portOfDischarge = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.PORT_OF_DISCHARGE
        )
        let placeOfDelivery = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.PLACE_OF_DELIVERY
        )
        let receiptStatus = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.RECEIPT_STATUS
        )
        let deliveryStatus = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.DELIVERY_STATUS
        )
        let itemLineNbr = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ITEM_LINE_NBR
        )
        let itemCode = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ITEM_CODE
        )
        let itemDescription = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ITEM_DESCRIPTION
        )
        let grosswtValue = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.GROSSWT_VALUE
        )
        let grosswtUnitcode = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.GROSSWT_UNITCODE
        )
        let grossVolumeValue = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.GROSS_VOLUME_VALUE
        )
        let grossVolumeUnitcode = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.GROSS_VOLUME_UNITCODE
        )
        let typeOfPkg = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.TYPE_OF_PKG
        )
        let totalNbrOfPkgs = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.TOTAL_NBR_OF_PKGS
        )
        let markings = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.MARKINGS
        )
        let fileType = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.FILE_TYPE
        )
        let source = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.SOURCE
        )
        let recordStatus = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.RECORD_STATUS
        )
        let processedDate = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.PROCESSED_DATE
        )
        let party = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.PARTY
        )
        let cargoDeliveryDate = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.CARGO_DELIVERY_DATE
        )
        let cargoReadyDate = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.CARGO_READY_DATE
        )
        let originReceipt = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.ORIGIN_RECEIPT
        )
        let destinationReceipt = translate(
            Namespace.SHIPPING_INSTRUCTION_REQUEST,
            ShippingInstructionRequestKeys.DESTINATION_RECEIPT
        )

        return {
            shippingInstructionRequest,
            id,
            documentName,
            messageId,
            senderId,
            recipientId,
            sentDate,
            sentTime,
            messageFunction,
            orderType,
            nbrOfConstsToStuff,
            nbrOfPreloadContsToExport,
            shipperReference,
            bookingReference,
            shipperPartycode,
            shipperPartyname,
            shipperAddressLine,
            shipperContactPersonName,
            shipperEmailAddress,
            carrierPartycode,
            carrierPartyname,
            consigneePartyname,
            consigneeAddressLine,
            notifypartyPartyname,
            stuffingDate,
            stuffingLocation,
            vesselName,
            voyageNbr,
            eta,
            etd,
            placeOfReceipt,
            portOfLoading,
            portOfDischarge,
            placeOfDelivery,
            receiptStatus,
            deliveryStatus,
            itemLineNbr,
            itemCode,
            itemDescription,
            grosswtValue,
            grosswtUnitcode,
            grossVolumeValue,
            grossVolumeUnitcode,
            typeOfPkg,
            totalNbrOfPkgs,
            markings,
            fileType,
            source,
            recordStatus,
            processedDate,
            party,
            cargoDeliveryDate,
            cargoReadyDate,
            originReceipt,
            destinationReceipt
        }
    }

    return (
        <Grid container spacing={3}>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.documentName}>
                <CngTextField
                    name="documentName"
                    label={translatedTextsObject.documentName}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageId}>
                <CngTextField
                    name="messageId"
                    label={translatedTextsObject.messageId}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.senderId}>
                <CngTextField
                    name="senderId"
                    label={translatedTextsObject.senderId + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.recipientId}>
                <CngTextField
                    name="recipientId"
                    label={translatedTextsObject.recipientId + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sentDate}>
                <CngTextField
                    name="sentDate"
                    label={translatedTextsObject.sentDate + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.sentTime}>
                <CngTextField
                    name="sentTime"
                    label={translatedTextsObject.sentTime + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageFunction}>
                <CngTextField
                    name="messageFunction"
                    label={translatedTextsObject.messageFunction}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.orderType}>
                <CngTextField
                    name="orderType"
                    label={translatedTextsObject.orderType}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.nbrOfConstsToStuff}>
                <CngTextField
                    name="nbrOfConstsToStuff"
                    label={translatedTextsObject.nbrOfConstsToStuff}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.nbrOfPreloadContsToExport}>
                <CngTextField
                    name="nbrOfPreloadContsToExport"
                    label={translatedTextsObject.nbrOfPreloadContsToExport}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperReference}>
                <CngTextField
                    name="shipperReference"
                    label={translatedTextsObject.shipperReference + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.bookingReference}>
                <CngTextField
                    name="bookingReference"
                    label={translatedTextsObject.bookingReference + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperPartycode}>
                <CngTextField
                    name="shipperPartycode"
                    label={translatedTextsObject.shipperPartycode}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperPartyname}>
                <CngTextField
                    name="shipperPartyname"
                    label={translatedTextsObject.shipperPartyname + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperAddressLine}>
                <CngTextField
                    name="shipperAddressLine"
                    label={translatedTextsObject.shipperAddressLine + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperContactPersonName}>
                <CngTextField
                    name="shipperContactPersonName"
                    label={translatedTextsObject.shipperContactPersonName + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperEmailAddress}>
                 <CngTextField
                     name="shipperEmailAddress"
                     label={translatedTextsObject.shipperEmailAddress + " *"}
                     disabled={disabled}
                 />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierPartycode}>
                <CngTextField
                    name="carrierPartycode"
                    label={translatedTextsObject.carrierPartycode + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierPartyname}>
                <CngTextField
                    name="carrierPartyname"
                    label={translatedTextsObject.carrierPartyname + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consigneePartyname}>
                <CngTextField
                    name="consigneePartyname"
                    label={translatedTextsObject.consigneePartyname + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.consigneeAddressLine}>
                <CngTextField
                    name="consigneeAddressLine"
                    label={translatedTextsObject.consigneeAddressLine + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.notifypartyPartyname}>
                <CngTextField
                    name="notifypartyPartyname"
                    label={translatedTextsObject.notifypartyPartyname + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stuffingDate}>
                <CngTextField
                    name="stuffingDate"
                    label={translatedTextsObject.stuffingDate}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.stuffingLocation}>
                <CngTextField
                    name="stuffingLocation"
                    label={translatedTextsObject.stuffingLocation}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselName}>
                <CngTextField
                    name="vesselName"
                    label={translatedTextsObject.vesselName + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNbr}>
                <CngTextField
                    name="voyageNbr"
                    label={translatedTextsObject.voyageNbr + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.eta}>
                <CngTextField
                    name="eta"
                    label={translatedTextsObject.eta + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.etd}>
                <CngTextField
                    name="etd"
                    label={translatedTextsObject.etd + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfReceipt}>
                <CngTextField
                    name="placeOfReceipt"
                    label={translatedTextsObject.placeOfReceipt + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfLoading}>
                <CngTextField
                    name="portOfLoading"
                    label={translatedTextsObject.portOfLoading + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portOfDischarge}>
                <CngTextField
                    name="portOfDischarge"
                    label={translatedTextsObject.portOfDischarge + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.placeOfDelivery}>
                <CngTextField
                    name="placeOfDelivery"
                    label={translatedTextsObject.placeOfDelivery + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.receiptStatus}>
                <CngTextField
                    name="receiptStatus"
                    label={translatedTextsObject.receiptStatus + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.deliveryStatus}>
                <CngTextField
                    name="deliveryStatus"
                    label={translatedTextsObject.deliveryStatus + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemLineNbr}>
                <CngTextField
                    name="itemLineNbr"
                    label={translatedTextsObject.itemLineNbr + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemCode}>
                <CngTextField
                    name="itemCode"
                    label={translatedTextsObject.itemCode + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.itemDescription}>
                <CngTextField
                    name="itemDescription"
                    label={translatedTextsObject.itemDescription + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grosswtValue}>
                <CngTextField
                    name="grosswtValue"
                    label={translatedTextsObject.grosswtValue + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grosswtUnitcode}>
                <CngTextField
                    name="grosswtUnitcode"
                    label={translatedTextsObject.grosswtUnitcode + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossVolumeValue}>
                <CngTextField
                    name="grossVolumeValue"
                    label={translatedTextsObject.grossVolumeValue + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.grossVolumeUnitcode}>
                <CngTextField
                    name="grossVolumeUnitcode"
                    label={translatedTextsObject.grossVolumeUnitcode + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.typeOfPkg}>
                <CngTextField
                    name="typeOfPkg"
                    label={translatedTextsObject.typeOfPkg + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.totalNbrOfPkgs}>
                <CngTextField
                    name="totalNbrOfPkgs"
                    label={translatedTextsObject.totalNbrOfPkgs + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.markings}>
                <CngTextField
                    name="markings"
                    label={translatedTextsObject.markings}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileType}>
                <CngTextField
                    name="fileType"
                    label={translatedTextsObject.fileType + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.source}>
                <CngTextField
                    name="source"
                    label={translatedTextsObject.source + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.recordStatus}>
                <CngTextField
                    name="recordStatus"
                    label={translatedTextsObject.recordStatus + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processedDate}>
                <CngDateTimeField
                    name="processedDate"
                    label={translatedTextsObject.processedDate + " *"}
                    format='YYYY-MM-DD HH:mm:ss'
                    toServerDataFormat='YYYY-MM-DD HH:mm:ss'
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.party}>
                <CngTextField
                    name="party"
                    label={translatedTextsObject.party + " *"}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDeliveryDate}>
                <CngDateTimeField
                    name="cargoDeliveryDate"
                    label={translatedTextsObject.cargoDeliveryDate + " *"}
                    format='YYYY-MM-DD HH:mm:ss'
                    toServerDataFormat='YYYY-MM-DD HH:mm:ss'
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoReadyDate}>
                <CngDateTimeField
                    name="cargoReadyDate"
                    label={translatedTextsObject.cargoReadyDate + " *"}
                    format='YYYY-MM-DD HH:mm:ss'
                    toServerDataFormat='YYYY-MM-DD HH:mm:ss'
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.originReceipt}>
                <CngTextField
                    name="originReceipt"
                    label={translatedTextsObject.originReceipt}
                    disabled={disabled}
                />
            </CngGridItem>
            <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.destinationReceipt}>
                <CngTextField
                    name="destinationReceipt"
                    label={translatedTextsObject.destinationReceipt}
                    disabled={disabled}
                />
            </CngGridItem>
        </Grid>
    )
}

function toClientDataFormat(serverData) {
    let localData = DataFlattener.parse(serverData);
    localData.processedDate = DateTimeFormatter.toClientDateTime(
        localData.processedDate,
        'YYYY-MM-DD HH:mm:ss'
    );
    return localData;
}

function toServerDataFormat(localData) {
    localData.processedDate = DateTimeFormatter.toServerDateTime(
        localData.processedDate,
        'YYYY-MM-DD HH:mm:ss'
    );
    return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
    formikProps: FORMIK_PROPS,
    Fields: Fields,
    toClientDataFormat: toClientDataFormat,
    toServerDataFormat: toServerDataFormat
})

export default FormProperties
