import makeValidationSchema from './PartyMappingMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import React, { useState, useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import PartyMappingKeys from 'src/constants/locale/key/PartyMapping'
import { Grid } from '@material-ui/core'
import {
  components,
  DateTimeFormatter,
  constants,
  useServices
} from 'cng-web-lib'
import CodeMaintainanceApi from 'src/views/common/api/CodeMatainanceApi'

const {
  form: {
    field: {
      CngTextField,
      CngDateField,
      CngSelectField,
      CngLookupAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: 0,
  carrierName: "",
  createdBy: "",
  createdDate: "",
  ediId: "",
  alias: "",
  partyId: "",
  updatedBy: "",
  updatedDate: "",
  otherPartyId: "",
  jsonProperties: "",
  shipperPartyId: "",
  ediFileFormat: "",
  shipperEdiId: "",
  shipperAlias: "",
  moduleCode: "",
  platform: "",
  shipperPlatform: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.PARTY_MAPPING)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { fetchRecords } = useServices()
  const [ediModules, setEdiModules] = useState([]);
  const [ediFileFormats, setEdiFileFormats] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [shipperPlatforms, setShipperPlatforms] = useState([]);
  const [parties, setParties] = useState([]);

  useEffect(() => {
    CodeMaintainanceApi.fetchCodeMaintainance(fetchRecords, constants.CodeMaintenanceType.CODE_MASTER,
      [{
        field: 'codeType',
        operator: 'in',
        value: ['EDI_MODULE', 'EDI_FILE_FORMAT', 'EDI_PLATFORM_CODE', 'EDI_SHIPPER_PLATFORM']
      }], false, onLoadDataSuccess)

    // fetchRecords.execute(
    //     `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
    //     {
    //     },
    //     (data) => {
    //         console.log("Parties is back")
    //         console.log("data: " +  data)
    //         if (data && data.content && data.content.length > 0) {
    //           setParties(
    //             data.content.map((datum) => {
    //               return {
    //                 text: datum.name,
    //                 value: datum.id
    //               }
    //             })
    //           )
    //       }
    //     },
    //     (error) => {
    //     })   
  }, [])


  function onLoadDataSuccess(data) {
    console.log("onLoadPortSuccess")
    console.log(data)
    if (data && data.length > 0) {
      setEdiModules(
        data.filter(d => d.codeType === 'EDI_MODULE').map((datum) => {
          return {
            text: datum.descriptionEn,
            value: datum.code
          }
        })
      )
      setEdiFileFormats(
        data.filter(d => d.codeType === 'EDI_FILE_FORMAT').map((datum) => {
          return {
            text: datum.descriptionEn,
            value: datum.code
          }
        })
      )
      setPlatforms(
        data.filter(d => d.codeType === 'EDI_PLATFORM_CODE').map((datum) => {
          return {
            text: datum.descriptionEn,
            value: datum.code
          }
        })
      )
      setShipperPlatforms(
        data.filter(d => d.codeType === 'EDI_SHIPPER_PLATFORM').map((datum) => {
          return {
            text: datum.descriptionEn,
            value: datum.code
          }
        })
      )
    }
  }

  function makeTranslatedTextsObject() {
    let id = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.ID
    )
    let carrierName = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.CARRIER_NAME
    )
    let createdBy = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.CREATED_DATE
    )
    let ediId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.EDI_ID
    )
    let alias = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.ALIAS
    )
    let partyId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.PARTY_ID
    )
    let updatedBy = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.UPDATED_DATE
    )
    let otherPartyId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.OTHER_PARTY_ID
    )
    let jsonProperties = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.JSON_PROPERTIES
    )
    let shipperPartyId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_PARTY_ID
    )
    let ediFileFormat = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.EDI_FILE_FORMAT
    )
    let shipperEdiId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_EDI_ID
    )
    let shipperAlias = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_ALIAS
    )
    let moduleCode = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.MODULE_CODE
    )
    let platform = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.PLATFORM
    )
    let shipperPlatform = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_PLATFORM
    )

    return {
      id,
      carrierName,
      createdBy,
      createdDate,
      ediId,
      alias,
      partyId,
      updatedBy,
      updatedDate,
      otherPartyId,
      jsonProperties,
      shipperPartyId,
      ediFileFormat,
      shipperEdiId,
      shipperAlias,
      moduleCode,
      platform,
      shipperPlatform
    }
  }

  return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
        <CngLookupAutocompleteField
          name='partyId'
          label={translatedTextsObject.partyId + " *"}
          disabled={disabled}
          lookupProps={{
            url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
            label: 'name',
            value: 'id'
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.otherPartyId}>
        <CngLookupAutocompleteField
          name='otherPartyId'
          label={translatedTextsObject.otherPartyId}
          disabled={disabled}
          lookupProps={{
            url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
            label: 'name',
            value: 'id'
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperPartyId}>
        <CngLookupAutocompleteField
          name='shipperPartyId'
          label={translatedTextsObject.shipperPartyId}
          disabled={disabled}
          lookupProps={{
            url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
            label: 'name',
            value: 'id'
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ediId}>
        <CngTextField
          name="ediId"
          label={translatedTextsObject.ediId + " *"}
          disabled={disabled}
        />
      </CngGridItem>

      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.alias}>
        <CngTextField
          name="alias"
          label={translatedTextsObject.alias}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperEdiId}>
        <CngTextField
          name="shipperEdiId"
          label={translatedTextsObject.shipperEdiId}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperAlias}>
        <CngTextField
          name="shipperAlias"
          label={translatedTextsObject.shipperAlias}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.jsonProperties}>
        <CngTextField
          name="jsonProperties"
          label={translatedTextsObject.jsonProperties}
          disabled={disabled}
        />
      </CngGridItem>

      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.ediFileFormat}>
        <CngSelectField
          name="ediFileFormat"
          type="string"
          label={translatedTextsObject.ediFileFormat}
          disabled={disabled}
          items={ediFileFormats} />
      </CngGridItem>

      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.moduleCode}>
        <CngSelectField
          name="moduleCode"
          type="string"
          label={translatedTextsObject.moduleCode + " *"}
          disabled={disabled}
          items={ediModules}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.platform}>
        <CngSelectField
          name="platform"
          type="string"
          label={translatedTextsObject.platform}
          disabled={disabled}
          items={platforms}
        />
      </CngGridItem>

      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperPlatform}>
        <CngSelectField
          name="shipperPlatform"
          type="string"
          label={translatedTextsObject.shipperPlatform}
          disabled={disabled}
          items={shipperPlatforms}
        />
      </CngGridItem>

    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  localData.createdDate = DateTimeFormatter.toClientDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toClientDate(
    localData.updatedDate
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.createdDate = DateTimeFormatter.toServerDate(
    localData.createdDate
  );
  localData.updatedDate = DateTimeFormatter.toServerDate(
    localData.updatedDate
  );
  return localData;
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
