import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import PlatformPortMappingApiUrls from 'src/apiUrls/PlatformPortMappingApiUrls'
import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import PlatformPortMappingKeys from 'src/constants/locale/key/PlatformPortMapping'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeRangeFilter: CngDateTimeRangeFilter,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

const {
  filter: { EQUAL }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props
  const fetchCustomLookup = useFetchCustomLookup();
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup();
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.PLATFORM_PORT_MAPPING
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    console.log("title: " + PlatformPortMappingKeys.TITLE)
    let platformPortMapping = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: platformPortMapping
      }
    )
    let platform = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.PLATFORM
    )
    let carrierPartyId = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.CARRIER_PARTY_ID
    )
    let calistaPortCode = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.CALISTA_PORT_CODE
    )
    let partyPortCode = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.PARTY_PORT_CODE
    )
    let partyPortDesc = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.PARTY_PORT_DESC
    )
    let messageType = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.MESSAGE_TYPE
    )
    let createdDate = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.CREATED_DATE
    )

    return {
      platformPortMapping,
      tableTitle,
      platform,
      carrierPartyId,
      calistaPortCode,
      partyPortCode,
      partyPortDesc,
      messageType,
      createdDate
    }
  }

  const columns = [
    {
      field: "platform",
      title: translatedTextsObject.platform,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_PLATFORM_CODE' }]
        )
      }
    },
    {
      field: "carrierPartyId",
      title: translatedTextsObject.carrierPartyId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          {},
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: "calistaPortCode",
      title: translatedTextsObject.calistaPortCode,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.PORT,
          (error) => console.error(error)
        )
      }
    },
    {
      field: "partyPortCode",
      title: translatedTextsObject.partyPortCode,
    },
    {
      field: "partyPortDesc",
      title: translatedTextsObject.partyPortDesc,
    },
    {
      field: "messageType",
      title: translatedTextsObject.messageType,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_MESSAGE_TYPE' }]
        )
      }
    },
    {
      field: "createdDate",
      title: translatedTextsObject.createdDate,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: PlatformPortMappingApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: PlatformPortMappingApiUrls.EXPORT }}
              fetch={{ url: PlatformPortMappingApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
