import PortMappingFormProperties from './PortMappingFormProperties'
import PortMappingApiUrls from 'src/apiUrls/PortMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngEditForm }
} = components

function PortMappingEditForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     }
}) {
    return (
        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <PortMappingFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={PortMappingFormProperties.formikProps}
            toClientDataFormat={PortMappingFormProperties.toClientDataFormat}
            toServerDataFormat={PortMappingFormProperties.toServerDataFormat}
            fetch={{
                url: PortMappingApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
            update={{
                url: PortMappingApiUrls.PUT
            }}
            id={id}
        />
    )
}

export default PortMappingEditForm
