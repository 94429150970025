import PartyMappingFormProperties from './PartyMappingFormProperties'
import PartyMappingApiUrls from 'src/apiUrls/PartyMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngEditForm }
} = components

function PartyMappingEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
  }
}) {
  return (
    <CngEditForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <PartyMappingFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={PartyMappingFormProperties.formikProps}
      toClientDataFormat={PartyMappingFormProperties.toClientDataFormat}
      toServerDataFormat={PartyMappingFormProperties.toServerDataFormat}
      fetch={{
        url: PartyMappingApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: PartyMappingApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default PartyMappingEditForm
