import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import TransactionLogViewForm from './TransactionLogViewForm'
import TransactionLogAddForm from './TransactionLogAddForm'
import TransactionLogEditForm from './TransactionLogEditForm'
import Namespace from 'src/constants/locale/Namespace'
import TransactionLogKeys from 'src/constants/locale/key/TransactionLog'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      UiComponentKeys,
    },
  },
} = constants

const { CngTabs } = components

function AddPage({ history, showNotification }) {
  const [lockedOnFirstTab, setLockedOnFirstTab] = useState(true)
  const [transactionLogId, setTransactionLogId] = useState('')
  const { translate } = useTranslation([
    Namespace.TRANSACTION_LOG,
    Namespace.UI_COMPONENT
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let transactionLog = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TITLE
    )
    let tabLockedMessage = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Tabs.PLEASE_SUBMIT_FIRST,
      {
        title: transactionLog
      }
    )

    return {
      transactionLog,
      tabLockedMessage
    }
  }

  function makeTransactionLogForm() {
    if (transactionLogId === '') {
      return (
        <TransactionLogAddForm
          history={history}
          showNotification={showNotification}
          onPostSubmitSuccess={(datum) => {
            setTransactionLogId(datum.id)
            setLockedOnFirstTab(false)
          }}
        />
      )
    } else {
      return (
        <TransactionLogEditForm
          showNotification={showNotification}
          id={transactionLogId}
        />
      )
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <TransactionLogViewForm
              showNotification={showNotification}
              id={transactionLogId}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.transactionLog,
              tabContent: makeTransactionLogForm()
            },
          ]}
          lockedOnFirstTab={lockedOnFirstTab}
          tabLockedMessage={translatedTextsObject.tabLockedMessage}
        />
      </Grid>
    </Grid>
  )
}

export default AddPage