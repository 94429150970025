import PartyMappingFormProperties from './PartyMappingFormProperties'
import PartyMappingApiUrls from 'src/apiUrls/PartyMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngViewForm }
} = components

function PartyMappingViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => {
    //do nothing
  }
}) {
  return (
    <CngViewForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      showNotification={showNotification}
      bodySection={
        <PartyMappingFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={PartyMappingFormProperties.formikProps}
      toClientDataFormat={PartyMappingFormProperties.toClientDataFormat}
      toServerDataFormat={PartyMappingFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: PartyMappingApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
    />
  )
}

export default PartyMappingViewForm
