import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
  constants,
} from 'cng-web-lib'

const {
  locale: {
    key: {
      CommonValidationMessageKeys,
    },
  },
} = constants

function makeValidationSchema(translate) {
  let requiredMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.REQUIRED
  )
  let dateTypeErrorMessage = translate(
    Namespace.COMMON_VALIDATION_MSG,
    CommonValidationMessageKeys.DATE_TYPE_ERROR
  )

  return Yup.object({
    id: Yup.number(),
    carrierName: Yup.string(),
    createdBy: Yup.number(),
    createdDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    ediId: Yup.string().required(requiredMessage).nullable(),
    alias: Yup.string(),
    partyId: Yup.number().required(requiredMessage).nullable(),
    updatedBy: Yup.number(),
    updatedDate: Yup.date().validFormat().typeError(dateTypeErrorMessage),
    otherPartyId: Yup.number(),
    jsonProperties: Yup.string(),
    shipperPartyId: Yup.number(),
    ediFileFormat: Yup.string(),
    shipperEdiId: Yup.string(),
    shipperAlias: Yup.string(),
    moduleCode: Yup.string().required(requiredMessage).nullable(),
    platform: Yup.string(),
    shipperPlatform: Yup.string(),
  })
}

export default makeValidationSchema
