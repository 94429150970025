import PlatformPortMappingFormProperties from './PlatformPortMappingFormProperties'
import PlatformPortMappingApiUrls from 'src/apiUrls/PlatformPortMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngEditForm }
} = components

function PlatformPortMappingEditForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     }
}) {
    return (
        <CngEditForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <PlatformPortMappingFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={PlatformPortMappingFormProperties.formikProps}
            toClientDataFormat={PlatformPortMappingFormProperties.toClientDataFormat}
            toServerDataFormat={PlatformPortMappingFormProperties.toServerDataFormat}
            fetch={{
                url: PlatformPortMappingApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
            update={{
                url: PlatformPortMappingApiUrls.PUT
            }}
            id={id}
        />
    )
}

export default PlatformPortMappingEditForm
