import React, { useState } from 'react'

function TransactionLogDecoratorComponet(props) {
    return (
        <label>
            {props.bold ? <b>{props.name}</b> : props.name}
        </label>
    )
}

export default TransactionLogDecoratorComponet
