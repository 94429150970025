import SinotransRegistrationFormProperties from './SinotransRegistrationFormProperties'
import SinotransRegistrationApiUrls from 'src/apiUrls/SinotransRegistrationApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngViewForm }
} = components

function SinotransRegistrationViewForm({
    showNotification,
    id,
    onFetchPreSuccess = () => {
        //do nothing
     }
}) {
    return (
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <SinotransRegistrationFormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                />
            }
            formikProps={SinotransRegistrationFormProperties.formikProps}
            toClientDataFormat={SinotransRegistrationFormProperties.toClientDataFormat}
            toServerDataFormat={SinotransRegistrationFormProperties.toServerDataFormat}
            fetch={{
                id: id,
                url: SinotransRegistrationApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
        />
    )
}

export default SinotransRegistrationViewForm
