import React, { useState, useEffect } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import PortMappingKeys from 'src/constants/locale/key/PortMapping'
import { Grid } from '@material-ui/core'
import {
  components,
  DataFlattener
} from 'cng-web-lib'
import makeValidationSchema from './PortMappingMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'

const {
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  partyId: "",
  calistaPortCode: "",
  partyPortCode: "",
  partyPortDesc: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.PORT_MAPPING)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let portMapping = translate(
      Namespace.PORT_MAPPING,
      PortMappingKeys.TITLE
    )
    let partyId = translate(
      Namespace.PORT_MAPPING,
      PortMappingKeys.PARTY_ID
    )
    let calistaPortCode = translate(
      Namespace.PORT_MAPPING,
      PortMappingKeys.CALISTA_PORT_CODE
    )
    let partyPortCode = translate(
      Namespace.PORT_MAPPING,
      PortMappingKeys.PARTY_PORT_CODE
    )
    let partyPortDesc = translate(
      Namespace.PORT_MAPPING,
      PortMappingKeys.PARTY_PORT_DESC
    )

    return {
      portMapping,
      partyId,
      calistaPortCode,
      partyPortCode,
      partyPortDesc
    }
  }

  return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyId}>
        <CngLookupAutocompleteField
          name='partyId'
          label={translatedTextsObject.partyId + ' *'}
          disabled={disabled}
          lookupProps={{
            url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
            label: 'name',
            value: 'id'
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.calistaPortCode}>
        <PortSelectAutoComplete
          name="calistaPortCode"
          // type="string"
          label={translatedTextsObject.calistaPortCode + " *"}
        // disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyPortCode}>
        <CngTextField
          name="partyPortCode"
          label={translatedTextsObject.partyPortCode + " *"}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyPortDesc}>
        <CngTextField
          name="partyPortDesc"
          label={translatedTextsObject.partyPortDesc + " *"}
          disabled={disabled}
        />
      </CngGridItem>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
