import ShippingInstructionRequestApiUrls from 'src/apiUrls/ShippingInstructionRequestApiUrls'
import ShippingInstructionRequestFormProperties from './ShippingInstructionRequestFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngEditForm }
} = components

function ShippingInstructionRequestEditForm({
  showNotification,
  id,
  onFetchPreSuccess = () => { 
    //do nothing
   }
}) {
  return (
    <CngEditForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      bodySection={
        <ShippingInstructionRequestFormProperties.Fields
          disabled={false}
          showNotification={showNotification}
        />
      }
      formikProps={ShippingInstructionRequestFormProperties.formikProps}
      toClientDataFormat={ShippingInstructionRequestFormProperties.toClientDataFormat}
      toServerDataFormat={ShippingInstructionRequestFormProperties.toServerDataFormat}
      fetch={{
        url: ShippingInstructionRequestApiUrls.GET,
        onPreSuccess: onFetchPreSuccess
      }}
      update={{
        url: ShippingInstructionRequestApiUrls.PUT
      }}
      id={id}
    />
  )
}

export default ShippingInstructionRequestEditForm
