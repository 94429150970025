import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import TransactionLogApiUrls from 'src/apiUrls/TransactionLogApiUrls'
import React from 'react'
import TransactionLogKeys from 'src/constants/locale/key/TransactionLog'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeRangeFilter: CngDateTimeRangeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.TRANSACTION_LOG
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let transactionLog = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: transactionLog
      }
    )
    let processClass = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.PROCESS_CLASS
    )
    let source = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.SOURCE
    )
    let destination = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.DESTINATION
    )
    let fileName = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.FILE_NAME
    )
    // let fileContent = translate(
    //   Namespace.TRANSACTION_LOG,
    //   TransactionLogKeys.FILE_CONTENT
    // )
    let fileSize = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.FILE_SIZE
    )
    let fileCreationDate = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.FILE_CREATION_DATE
    )
    let serverFilePath = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.SERVER_FILE_PATH
    )
    let transferType = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TRANSFER_TYPE
    )
    let transferMode = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TRANSFER_MODE
    )
    let processStatus = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.PROCESS_STATUS
    )
    let statusDetail = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.STATUS_DETAIL
    )
    let startTime = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.START_TIME
    )
    let endTime = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.END_TIME
    )
    let transactionId = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TRANSACTION_ID
    )
    let dockey = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.DOCKEY
    )
    let retry = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.RETRY
    )

    return {
      transactionLog,
      tableTitle,
      processClass,
      source,
      destination,
      fileName,
      // fileContent,
      fileSize,
      fileCreationDate,
      serverFilePath,
      transferType,
      transferMode,
      processStatus,
      statusDetail,
      startTime,
      endTime,
      transactionId,
      dockey,
      retry
    }
  }

  const columns = [
    {
      field: "processClass",
      title: translatedTextsObject.processClass,
    },
    {
      field: "source",
      title: translatedTextsObject.source,
    },
    {
      field: "transferType",
      title: translatedTextsObject.transferType,
    },
    {
      field: "transferMode",
      title: translatedTextsObject.transferMode,
    },
    {
      field: "processStatus",
      title: translatedTextsObject.processStatus,
    },
    {
      field: "statusDetail",
      title: translatedTextsObject.statusDetail,
    },
    {
      field: "startTime",
      title: translatedTextsObject.startTime,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
      defaultSort: "desc"
    },
    {
      field: "endTime",
      title: translatedTextsObject.endTime,
      type: "datetime",
      filterComponent: CngDateTimeRangeFilter,
    },
    {
      field: "transactionId",
      title: translatedTextsObject.transactionId,
    },
    {
      field: "dockey",
      title: translatedTextsObject.dockey,
    },
    {
      field: "retry",
      title: translatedTextsObject.retry,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              // addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: TransactionLogApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: TransactionLogApiUrls.EXPORT_DOWNLOAD }}
              fetch={{ url: TransactionLogApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
