import PlatformPortMappingFormProperties from './PlatformPortMappingFormProperties'
import PlatformPortMappingApiUrls from 'src/apiUrls/PlatformPortMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngAddForm }
} = components

function PlatformPortMappingAddForm({
    history,
    showNotification,
    onPostSubmitSuccess
}) {
    return (
        <CngAddForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            history={history}
            showNotification={showNotification}
            bodySection={
                <PlatformPortMappingFormProperties.Fields
                    showNotification={showNotification}
                />
            }
            formikProps={PlatformPortMappingFormProperties.formikProps}
            toClientDataFormat={PlatformPortMappingFormProperties.toClientDataFormat}
            toServerDataFormat={PlatformPortMappingFormProperties.toServerDataFormat}
            create={{
                url: PlatformPortMappingApiUrls.POST,
                onPostSubmitSuccess: onPostSubmitSuccess
            }}
        />
    )
}

export default PlatformPortMappingAddForm
