import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import PartyMappingApiUrls from 'src/apiUrls/PartyMappingApiUrls'
import React, { useState, useEffect } from 'react'
import PartyMappingKeys from 'src/constants/locale/key/PartyMapping'
import Namespace from 'src/constants/locale/Namespace'
import check from 'check-types'
import get from 'lodash/get'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateRangeFilter: CngDateRangeFilter,
    useDefaultNotification,
    useFetchCustomLookup,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {
    FilterOperator: EQUAL
  }
} = constants


function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props
  const fetchCustomLookup = useFetchCustomLookup();
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup();
  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.PARTY_MAPPING
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let partyMapping = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: partyMapping
      }
    )
    let id = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.ID
    )
    let carrierName = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.CARRIER_NAME
    )
    let createdBy = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.CREATED_BY
    )
    let createdDate = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.CREATED_DATE
    )
    let ediId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.EDI_ID
    )
    let alias = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.ALIAS
    )
    let partyId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.PARTY_ID
    )
    let updatedBy = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.UPDATED_BY
    )
    let updatedDate = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.UPDATED_DATE
    )
    let otherPartyId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.OTHER_PARTY_ID
    )
    let jsonProperties = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.JSON_PROPERTIES
    )
    let shipperPartyId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_PARTY_ID
    )
    let ediFileFormat = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.EDI_FILE_FORMAT
    )
    let shipperEdiId = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_EDI_ID
    )
    let shipperAlias = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_ALIAS
    )
    let moduleCode = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.MODULE_CODE
    )
    let platform = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.PLATFORM
    )
    let shipperPlatform = translate(
      Namespace.PARTY_MAPPING,
      PartyMappingKeys.SHIPPER_PLATFORM
    )

    return {
      partyMapping,
      tableTitle,
      id,
      carrierName,
      createdBy,
      createdDate,
      ediId,
      alias,
      partyId,
      updatedBy,
      updatedDate,
      otherPartyId,
      jsonProperties,
      shipperPartyId,
      ediFileFormat,
      shipperEdiId,
      shipperAlias,
      moduleCode,
      platform,
      shipperPlatform
    }
  }

  const columns = [
    {
      field: 'partyId',
      title: translatedTextsObject.partyId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          {},
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: 'otherPartyId',
      title: translatedTextsObject.otherPartyId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          {},
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: 'shipperPartyId',
      title: translatedTextsObject.shipperPartyId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          {},
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: 'ediId',
      title: translatedTextsObject.ediId
    },
    {
      field: 'alias',
      title: translatedTextsObject.alias,
    },
    {
      field: 'moduleCode',
      title: translatedTextsObject.moduleCode,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_MODULE' }]
        )
      }
    },
    {
      field: 'ediFileFormat',
      title: translatedTextsObject.ediFileFormat,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_FILE_FORMAT' }]
        )
      }
    }, {
      field: 'platform',
      title: translatedTextsObject.platform,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_PLATFORM_CODE' }]
        )
      }
    }, {
      field: 'shipperPlatform',
      title: translatedTextsObject.shipperPlatform,
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          (error) => console.error(error),
          [{ field: 'codeType', operator: EQUAL, value: 'EDI_SHIPPER_PLATFORM' }]
        )
      }
    }
  ];

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: PartyMappingApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: PartyMappingApiUrls.EXPORT }}
              fetch={{ url: PartyMappingApiUrls.GET }}
              idAccessor='id'
              notification={notification}
              //tableConfigurationCode='REPLACE_WITH_CORE_TABLE_CONFIG_CODE'
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default TablePage
