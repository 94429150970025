import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import PortMappingViewForm from './PortMappingViewForm'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import PortMappingKeys from 'src/constants/locale/key/PortMapping'

const { CngTabs } = components


function ViewPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.PORT_MAPPING])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let portMapping = translate(
      Namespace.PORT_MAPPING,
      PortMappingKeys.TITLE
    )

    return {
      portMapping
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <PortMappingViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
                //do nothing
              }}
            />
          </Container>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.portMapping,
              tabContent: (
                <PortMappingViewForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default ViewPage