import { Yup } from 'cng-web-lib'
import Namespace from 'src/constants/locale/Namespace'
import {
    constants,
} from 'cng-web-lib'

const {
    locale: {
        key: {
            CommonValidationMessageKeys,
        },
    },
} = constants

function ShippingInstructionRequestMakeValidationSchema(translate) {
    let requiredMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.REQUIRED
    )
    let dateTypeErrorMessage = translate(
        Namespace.COMMON_VALIDATION_MSG,
        CommonValidationMessageKeys.DATE_TYPE_ERROR
    )

    return Yup.object({
        documentName: Yup.string(),
        messageId: Yup.string(),
        senderId: Yup.string().required(requiredMessage),
        recipientId: Yup.string().required(requiredMessage),
        sentDate: Yup.string().required(requiredMessage),
        sentTime: Yup.string().required(requiredMessage),
        messageFunction: Yup.string(),
        orderType: Yup.string(),
        nbrOfConstsToStuff: Yup.string(),
        nbrOfPreloadContsToExport: Yup.string(),
        shipperReference: Yup.string().required(requiredMessage),
        bookingReference: Yup.string().required(requiredMessage),
        shipperPartycode: Yup.string(),
        shipperPartyname: Yup.string().required(requiredMessage),
        shipperAddressLine: Yup.string().required(requiredMessage),
        shipperContactPersonName: Yup.string(),
        shipperEmailAddress: Yup.string(),
        carrierPartycode: Yup.string().required(requiredMessage),
        carrierPartyname: Yup.string().required(requiredMessage),
        consigneePartyname: Yup.string().required(requiredMessage),
        consigneeAddressLine: Yup.string().required(requiredMessage),
        notifypartyPartyname: Yup.string().required(requiredMessage),
        stuffingDate: Yup.string(),
        stuffingLocation: Yup.string(),
        vesselName: Yup.string().required(requiredMessage),
        voyageNbr: Yup.string().required(requiredMessage),
        eta: Yup.string().required(requiredMessage),
        etd: Yup.string().required(requiredMessage),
        placeOfReceipt: Yup.string().required(requiredMessage),
        portOfLoading: Yup.string().required(requiredMessage),
        portOfDischarge: Yup.string().required(requiredMessage),
        placeOfDelivery: Yup.string().required(requiredMessage),
        receiptStatus: Yup.string().required(requiredMessage),
        deliveryStatus: Yup.string().required(requiredMessage),
        itemLineNbr: Yup.string().required(requiredMessage),
        itemCode: Yup.string().required(requiredMessage),
        itemDescription: Yup.string().required(requiredMessage),
        grosswtValue: Yup.string().required(requiredMessage),
        grosswtUnitcode: Yup.string().required(requiredMessage),
        grossVolumeValue: Yup.string().required(requiredMessage),
        grossVolumeUnitcode: Yup.string().required(requiredMessage),
        typeOfPkg: Yup.string().required(requiredMessage),
        totalNbrOfPkgs: Yup.string().required(requiredMessage),
        markings: Yup.string(),
        fileType: Yup.string().required(requiredMessage),
        source: Yup.string().required(requiredMessage),
        recordStatus: Yup.string().required(requiredMessage),
        processedDate: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid.").required(requiredMessage),
        party: Yup.string().required(requiredMessage),
        cargoDeliveryDate: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid.").required(requiredMessage),
        cargoReadyDate: Yup.date().validFormat("YYYY-MM-DD HH:mm:ss").typeError("Invalid date format. Only \"YYYY-MM-DD HH:mm:ss\" format is valid.").required(requiredMessage),
        originReceipt: Yup.string(),
        destinationReceipt: Yup.string()
    })
}

export default ShippingInstructionRequestMakeValidationSchema