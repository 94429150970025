import { Container, Grid, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { components, useTranslation } from 'cng-web-lib'

import SinotransRegistrationViewForm from './SinotransRegistrationViewForm'
import SinotransRegistrationEditForm from './SinotransRegistrationEditForm'
import { useParams } from 'react-router-dom'
import Namespace from 'src/constants/locale/Namespace'
import SinotransRegistrationKeys from 'src/constants/locale/key/SinotransRegistration'

const { CngTabs } = components


function EditPage({ showNotification }) {
  const { id } = useParams()
  const { translate } = useTranslation([Namespace.SINOTRANS_REGISTRATION])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let sinotransRegistration = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TITLE
    )

    return {
      sinotransRegistration
    }
  }

  return (
    <Grid container spacing={3}>
      {/* <Grid item xs={12}>
        <Paper>
          <Container maxWidth='xl'>
            <SinotransRegistrationViewForm
              showNotification={showNotification}
              id={id}
              onFetchPreSuccess={(datum) => {
              }}
            />
          </Container>
        </Paper>
      </Grid> */}
      <Grid item xs={12}>
        <CngTabs
          headerColor='primary'
          tabs={[
            {
              tabName: translatedTextsObject.sinotransRegistration,
              tabContent: (
                <SinotransRegistrationEditForm
                  showNotification={showNotification}
                  id={id}
                />
              )
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditPage