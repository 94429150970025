import ShippingInstructionRequestApiUrls from 'src/apiUrls/ShippingInstructionRequestApiUrls'
import ShippingInstructionRequestFormProperties from './ShippingInstructionRequestFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngViewForm }
} = components

function ShippingInstructionRequestViewForm({
  showNotification,
  id,
  onFetchPreSuccess = () => { 
    //do nothing 
  }
}) {
  return (
    <CngViewForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      showNotification={showNotification}
      bodySection={
        <ShippingInstructionRequestFormProperties.Fields
          disabled={true}
          showNotification={showNotification}
        />
      }
      formikProps={ShippingInstructionRequestFormProperties.formikProps}
      toClientDataFormat={ShippingInstructionRequestFormProperties.toClientDataFormat}
      toServerDataFormat={ShippingInstructionRequestFormProperties.toServerDataFormat}
      fetch={{
        id: id,
        url: ShippingInstructionRequestApiUrls.GET
      }}
    />
  )
}

export default ShippingInstructionRequestViewForm
