import TransactionLogApiUrls from 'src/apiUrls/TransactionLogApiUrls'
import TransactionLogFormProperties from './TransactionLogFormProperties'
import React from 'react'
import { components } from 'cng-web-lib'

const {
  form: { CngAddForm }
} = components

function TransactionLogAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
      history={history}
      showNotification={showNotification}
      bodySection={
        <TransactionLogFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={TransactionLogFormProperties.formikProps}
      toClientDataFormat={TransactionLogFormProperties.toClientDataFormat}
      toServerDataFormat={TransactionLogFormProperties.toServerDataFormat}
      create={{
        url: TransactionLogApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default TransactionLogAddForm
