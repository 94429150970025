import PlatformPortMappingFormProperties from './PlatformPortMappingFormProperties'
import PlatformPortMappingApiUrls from 'src/apiUrls/PlatformPortMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
    form: { CngViewForm }
} = components

function PlatformPortMappingViewForm({
    showNotification,
    id,
    onFetchPreSuccess = () => { 
        //do nothing
    }
}) {
    return (
        <CngViewForm
            fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
            showNotification={showNotification}
            bodySection={
                <PlatformPortMappingFormProperties.Fields
                    disabled={true}
                    showNotification={showNotification}
                />
            }
            formikProps={PlatformPortMappingFormProperties.formikProps}
            toClientDataFormat={PlatformPortMappingFormProperties.toClientDataFormat}
            toServerDataFormat={PlatformPortMappingFormProperties.toServerDataFormat}
            fetch={{
                id: id,
                url: PlatformPortMappingApiUrls.GET,
                onPreSuccess: onFetchPreSuccess
            }}
        />
    )
}

export default PlatformPortMappingViewForm
