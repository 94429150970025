import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import SinotransRegistrationKeys from 'src/constants/locale/key/SinotransRegistration'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
} from 'cng-web-lib'
import makeValidationSchema from './SinotransRegistrationMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField,
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  shipperPartyId: "",
  userCode: "",
  companyName: "",
  country: "",
  title: "",
  companyAddress: "",
  firstName: "",
  lastName: "",
  tel: "",
  email: "",
  postcode: "",
  companyRole: "",
  uscc: "",
  industry: "",
  productInterest: "",
  status: "",
  remark: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.SINOTRANS_REGISTRATION)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let sinotransRegistration = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TITLE
    )
    let shipperPartyId = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.SHIPPER_PARTY_ID
    )
    let userCode = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.USER_CODE
    )
    let companyName = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COMPANY_NAME
    )
    let country = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COUNTRY
    )
    let title = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TITLE_NAME
    )
    let companyAddress = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COMPANY_ADDRESS
    )
    let firstName = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.FIRST_NAME
    )
    let lastName = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.LAST_NAME
    )
    let tel = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.TEL
    )
    let email = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.EMAIL
    )
    let postcode = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.POSTCODE
    )
    let companyRole = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.COMPANY_ROLE
    )
    let uscc = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.USCC
    )
    let industry = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.INDUSTRY
    )
    let productInterest = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.PRODUCT_INTEREST
    )
    let status = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.STATUS
    )
    let remark = translate(
      Namespace.SINOTRANS_REGISTRATION,
      SinotransRegistrationKeys.REMARK
    )

    return {
      sinotransRegistration,
      shipperPartyId,
      userCode,
      companyName,
      country,
      title,
      companyAddress,
      firstName,
      lastName,
      tel,
      email,
      postcode,
      companyRole,
      uscc,
      industry,
      productInterest,
      status,
      remark
    }
  }

	return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.sinotransRegistration} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.shipperPartyId}>
            <CngLookupAutocompleteField
              name="shipperPartyId"
              label={translatedTextsObject.shipperPartyId + " *"}
              disabled={disabled}
              lookupProps={{
                url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
                label: 'name',
                value: 'id'
              }}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.userCode}>
            <CngTextField
              name="userCode"
              label={translatedTextsObject.userCode + " *"}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.companyName}>
            <CngTextField
              name="companyName"
              label={translatedTextsObject.companyName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.country}>
            <CngTextField
              name="country"
              label={translatedTextsObject.country}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.titleName}>
            <CngTextField
              name="title"
              label={translatedTextsObject.title}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.companyAddress}>
            <CngTextField
              name="companyAddress"
              label={translatedTextsObject.companyAddress}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.firstName}>
            <CngTextField
              name="firstName"
              label={translatedTextsObject.firstName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.lastName}>
            <CngTextField
              name="lastName"
              label={translatedTextsObject.lastName}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.tel}>
            <CngTextField
              name="tel"
              label={translatedTextsObject.tel}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.email}>
            <CngTextField
              name="email"
              label={translatedTextsObject.email}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.postcode}>
            <CngTextField
              name="postcode"
              label={translatedTextsObject.postcode}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.companyRole}>
            <CngTextField
              name="companyRole"
              label={translatedTextsObject.companyRole}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.uscc}>
            <CngTextField
              name="uscc"
              label={translatedTextsObject.uscc}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.industry}>
            <CngTextField
              name="industry"
              label={translatedTextsObject.industry}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.productInterest}>
            <CngTextField
              name="productInterest"
              label={translatedTextsObject.productInterest}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.status}>
            <CngTextField
              name="status"
              label={translatedTextsObject.status}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.remark}>
            <CngTextField
              name="remark"
              label={translatedTextsObject.remark}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
	)
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
