import React, { useState } from 'react'

import { Box } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreIcon from '@material-ui/icons/MoreVert'
import SaveAltIcon from '@material-ui/icons/SaveAlt';

export default function TransactionLogActionMenu(props) {
    const { id, downloadRowClick } = props
    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        console.log("handle close")
        setAnchorEl(null)
    }

    const downloadAction = (idx) => () => {
        console.log("download " + idx)
        downloadRowClick(idx)
        setAnchorEl(null)
    }


    return (
        <Box >
            <IconButton
                edge='end'
                className={`row-more-actions`}
                onClick={handleClick}
            >
                <MoreIcon />
            </IconButton>
            <Menu
                id='long-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        maxHeight: 'auto',
                        width: 'auto'
                    }
                }}
            >
                <MenuItem onClick={downloadAction(id)}>
                    <SaveAltIcon />Download
                </MenuItem>

            </Menu>
        </Box>
    )
}
