import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import ShippingInstructionRequestApiUrls from 'src/apiUrls/ShippingInstructionRequestApiUrls'
import React from 'react'
import ShippingInstructionRequestKeys from 'src/constants/locale/key/ShippingInstructionRequest'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    DateTimeFilter: CngDateTimeFilter,
    useDefaultNotification
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.SHIPPING_INSTRUCTION_REQUEST
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let shippingInstructionRequest = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: shippingInstructionRequest
      }
    )
    let id = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.ID
    )
    let shipperReference = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.SHIPPER_REFERNCE
    )
    let bookingReference = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.BOOKING_REFERNCE
    )
    let fileType = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.FILE_TYPE
    )
    let source = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.SOURCE
    )
    let recordStatus = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.RECORD_STATUS
    )
    let processedDate = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.PROCESSED_DATE
    )
    let party = translate(
      Namespace.SHIPPING_INSTRUCTION_REQUEST,
      ShippingInstructionRequestKeys.PARTY
    )

    return {
      shippingInstructionRequest,
      tableTitle,
      id,
      shipperReference,
      bookingReference,
      fileType,
      source,
      recordStatus,
      processedDate,
      party
    }
  }

  const columns = [
    {
      field: "id",
      title: translatedTextsObject.id,
      type: "numeric",
    },
    {
      field: "shipperReference",
      title: translatedTextsObject.shipperReference,
    },
    {
      field: "bookingReference",
      title: translatedTextsObject.bookingReference,
    },
    {
      field: "fileType",
      title: translatedTextsObject.fileType,
    },
    {
      field: "source",
      title: translatedTextsObject.source,
    },
    {
      field: "recordStatus",
      title: translatedTextsObject.recordStatus,
    },
    {
      field: "processedDate",
      title: translatedTextsObject.processedDate,
      type: "datetime",
      filterComponent: CngDateTimeFilter,
    },
    {
      field: "party",
      title: translatedTextsObject.party,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: ShippingInstructionRequestApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: ShippingInstructionRequestApiUrls.EXPORT }}
              fetch={{ url: ShippingInstructionRequestApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
