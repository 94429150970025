import PartyMappingFormProperties from './PartyMappingFormProperties'
import PartyMappingApiUrls from 'src/apiUrls/PartyMappingApiUrls'
import { components } from 'cng-web-lib'
import React from 'react'

const {
  form: { CngAddForm }
} = components

function PartyMappingAddForm({
  history,
  showNotification,
  onPostSubmitSuccess
}) {
  return (
    <CngAddForm
      fieldLevel='toBeChangedByDeveloperIfWantFieldLevelRestriction'
      history={history}
      showNotification={showNotification}
      bodySection={
        <PartyMappingFormProperties.Fields
          showNotification={showNotification}
        />
      }
      formikProps={PartyMappingFormProperties.formikProps}
      toClientDataFormat={PartyMappingFormProperties.toClientDataFormat}
      toServerDataFormat={PartyMappingFormProperties.toServerDataFormat}
      create={{
        url: PartyMappingApiUrls.POST,
        onPostSubmitSuccess: onPostSubmitSuccess
      }}
    />
  )
}

export default PartyMappingAddForm
