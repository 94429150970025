import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation
} from 'cng-web-lib'

import NbsMappingApiUrls from 'src/apiUrls/NbsMappingApiUrls'
import React from 'react'
import NbsMappingKeys from 'src/constants/locale/key/NbsMapping'
import Namespace from 'src/constants/locale/Namespace'

const {
  card: { CngSimpleCardHeader },
  table: {
    CngCrudTable,
    useDefaultNotification,
    useFetchCustomLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  }
} = constants

function TablePage(props) {
  const fetchCustomLookup = useFetchCustomLookup();
  const {
    location: { pathname },
    showNotification
  } = props

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.NBS_MAPPING
  ])
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let nbsMapping = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: nbsMapping
      }
    )
    let partyId = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.PARTY_ID
    )
    let chargeCode = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.CHARGE_CODE
    )
    let type = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.TYPE
    )
    let account = translate(
      Namespace.NBS_MAPPING,
      NbsMappingKeys.ACCOUNT
    )

    return {
      nbsMapping,
      tableTitle,
      partyId,
      chargeCode,
      type,
      account
    }
  }

  const columns = [
    {
      field: 'partyId',
      title: translatedTextsObject.partyId,
      customLookup: () => {
        return fetchCustomLookup(
          `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
          {},
          'content',
          'name',
          'id',
          (error) => console.error(error)
        )
      }
    },
    {
      field: "chargeCode",
      title: translatedTextsObject.chargeCode,
    },
    {
      field: "type",
      title: translatedTextsObject.type,
    },
    {
      field: "account",
      title: translatedTextsObject.account,
    }
  ]

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
             // del={{ url: NbsMappingApiUrls.DELETE }}
              editRoute={`${pathname}/edit`}
              exportData={{ url: NbsMappingApiUrls.EXPORT }}
              fetch={{ url: NbsMappingApiUrls.GET }}
              idAccessor="id"
              notification={notification}
              //tableConfigurationCode="REPLACE_WITH_CORE_TABLE_CONFIG_CODE"
              viewRoute={`${pathname}/view`}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default TablePage
