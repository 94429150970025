import React from 'react'
import Namespace from 'src/constants/locale/Namespace'
import PlatformPortMappingKeys from 'src/constants/locale/key/PlatformPortMapping'
import { Grid } from '@material-ui/core'
import {
  components,
  DataFlattener
} from 'cng-web-lib'
import makeValidationSchema from './PlatformPortMappingMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import PortSelectAutoComplete from 'src/views/vesselschedule/searchschedule/PortSelectAutoComplete'

const {
  form: {
    field: {
      CngTextField,
      CngLookupAutocompleteField,
      CngCodeMasterAutocompleteField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  platform: "",
  carrierPartyId: "",
  calistaPortCode: "",
  partyPortCode: "",
  partyPortDesc: "",
  messageType: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap }) {
  const { translate } = useTranslation(Namespace.PLATFORM_PORT_MAPPING)
  const translatedTextsObject = makeTranslatedTextsObject()

  function makeTranslatedTextsObject() {
    let platformPortMapping = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.TITLE
    )
    let platform = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.PLATFORM
    )
    let carrierPartyId = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.CARRIER_PARTY_ID
    )
    let calistaPortCode = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.CALISTA_PORT_CODE
    )
    let partyPortCode = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.PARTY_PORT_CODE
    )
    let partyPortDesc = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.PARTY_PORT_DESC
    )
    let messageType = translate(
      Namespace.PLATFORM_PORT_MAPPING,
      PlatformPortMappingKeys.MESSAGE_TYPE
    )

    return {
      platformPortMapping,
      platform,
      carrierPartyId,
      calistaPortCode,
      partyPortCode,
      partyPortDesc,
      messageType
    }
  }

  return (
    <Grid container spacing={3}>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.platform}>
        <CngCodeMasterAutocompleteField
          name='platform'
          label={translatedTextsObject.platform}
          disabled={disabled}
          codeType='EDI_PLATFORM_CODE'
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierPartyId}>
        <CngLookupAutocompleteField
          name='carrierPartyId'
          label={translatedTextsObject.carrierPartyId + ' *'}
          disabled={disabled}
          lookupProps={{
            url: `${process.env.REACT_APP_TPR_ORIGIN_URL}/tpr/party/get`,
            label: 'name',
            value: 'id'
          }}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.calistaPortCode}>
        <PortSelectAutoComplete
          name="calistaPortCode"
          // type="string"
          label={translatedTextsObject.calistaPortCode + " *"}
        // disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyPortCode}>
        <CngTextField
          name="partyPortCode"
          label={translatedTextsObject.partyPortCode + " *"}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.partyPortDesc}>
        <CngTextField
          name="partyPortDesc"
          label={translatedTextsObject.partyPortDesc + " *"}
          disabled={disabled}
        />
      </CngGridItem>
      <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.messageType}>
        <CngCodeMasterAutocompleteField
          name='messageType'
          label={translatedTextsObject.messageType}
          disabled={disabled}
          codeType='EDI_MESSAGE_TYPE'
        />
      </CngGridItem>
    </Grid>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  return localData;
}

function toServerDataFormat(localData) {
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
