import React, { useEffect, useState } from 'react'
import Namespace from 'src/constants/locale/Namespace'
import TransactionLogKeys from 'src/constants/locale/key/TransactionLog'
import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  DataFlattener,
  DateTimeFormatter,
  useServices
} from 'cng-web-lib'
import makeValidationSchema from './TransactionLogMakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import TransactionLogApiUrls from 'src/apiUrls/TransactionLogApiUrls'
import TransactionLogComponents from '../components/TransactionLogComponents'

const {
  card: {
    CngSimpleCardHeader,
  },
  form: {
    field: {
      CngTextField,
      CngDateTimeField
    },
  },
  CngGridItem,
} = components

const DEFAULT_INITIAL_VALUES = Object.freeze({
  processClass: "",
  source: "",
  destination: "",
  fileName: "",
  fileContent: "",
  fileSize: "",
  fileCreationDate: "",
  serverFilePath: "",
  transferType: "",
  transferMode: "",
  processStatus: "",
  statusDetail: "",
  startTime: "",
  endTime: "",
  transactionId: "",
  dockey: "",
  retry: "",
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}

function Fields({ disabled, showNotification, shouldHideMap, isEdit, items }) {
  const { translate } = useTranslation(Namespace.TRANSACTION_LOG)
  const translatedTextsObject = makeTranslatedTextsObject()
  const { securedSendRequest } = useServices()
  // const [transactionLog, setTransactionLog] = useState()

  // setTransactionLog(items)

  var disbaledCheck = false

  if (isEdit) {
    disbaledCheck = isEdit
  } else {
    disbaledCheck = disabled
  }
  const downloadUploadedRowClick = (id) => {
    downloadFile4(id)
  }

  function downloadFile4(id) {

    securedSendRequest.execute(
      "POST",
      TransactionLogApiUrls.EXPORT,
      { id: id },
      (data) => {
        console.log("success")
        console.log(data)

        var blob = data.data

        //console.log(blob)

        var link = document.createElement('a');

        link.href = window.URL.createObjectURL(blob);
        link.download = items.fileName != null ? items.fileName : items.id

        document.body.appendChild(link);

        console.log(link)
        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);

      },
      (error) => {
        console.log(error)
      },
      onComplete,

      { responseType: "blob" }

    )
  }

  function onComplete() {
    console.log("complete")
  }

  function makeTranslatedTextsObject() {
    let transactionLog = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TITLE
    )
    let processClass = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.PROCESS_CLASS
    )
    let source = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.SOURCE
    )
    let destination = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.DESTINATION
    )
    let fileName = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.FILE_NAME
    )
    let fileContent = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.FILE_CONTENT
    )
    let fileSize = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.FILE_SIZE
    )
    let fileCreationDate = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.FILE_CREATION_DATE
    )
    let serverFilePath = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.SERVER_FILE_PATH
    )
    let transferType = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TRANSFER_TYPE
    )
    let transferMode = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TRANSFER_MODE
    )
    let processStatus = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.PROCESS_STATUS
    )
    let statusDetail = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.STATUS_DETAIL
    )
    let startTime = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.START_TIME
    )
    let endTime = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.END_TIME
    )
    let transactionId = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.TRANSACTION_ID
    )
    let dockey = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.DOCKEY
    )
    let retry = translate(
      Namespace.TRANSACTION_LOG,
      TransactionLogKeys.RETRY
    )

    return {
      transactionLog,
      processClass,
      source,
      destination,
      fileName,
      fileContent,
      fileSize,
      fileCreationDate,
      serverFilePath,
      transferType,
      transferMode,
      processStatus,
      statusDetail,
      startTime,
      endTime,
      transactionId,
      dockey,
      retry
    }
  }

  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.transactionLog} />
      <CardContent>
        <Grid container spacing={3}>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processClass}>
            <CngTextField
              name="processClass"
              label={translatedTextsObject.processClass}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.source}>
            <CngTextField
              name="source"
              label={translatedTextsObject.source}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.destination}>
            <CngTextField
              name="destination"
              label={translatedTextsObject.destination}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileName}>
            <CngTextField
              name="fileName"
              label={translatedTextsObject.fileName}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileContent}>
            <TransactionLogComponents tile={items}
              downloadRowClick={downloadUploadedRowClick}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileSize}>
            <CngTextField
              name="fileSize"
              type="number"
              label={translatedTextsObject.fileSize}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.fileCreationDate}>
            <CngDateTimeField
              name="fileCreationDate"
              label={translatedTextsObject.fileCreationDate}
              format='YYYY-MM-DD HH:mm:ss'
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.serverFilePath}>
            <CngTextField
              name="serverFilePath"
              label={translatedTextsObject.serverFilePath}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transferType}>
            <CngTextField
              name="transferType"
              label={translatedTextsObject.transferType}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transferMode}>
            <CngTextField
              name="transferMode"
              label={translatedTextsObject.transferMode}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.processStatus}>
            <CngTextField
              name="processStatus"
              label={translatedTextsObject.processStatus}
              disabled={disabled}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.statusDetail}>
            <CngTextField
              name="statusDetail"
              label={translatedTextsObject.statusDetail}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.startTime}>
            <CngDateTimeField
              name="startTime"
              label={translatedTextsObject.startTime}
              format='YYYY-MM-DD HH:mm:ss'
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.endTime}>
            <CngDateTimeField
              name="endTime"
              label={translatedTextsObject.endTime}
              format='YYYY-MM-DD HH:mm:ss'
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.transactionId}>
            <CngTextField
              name="transactionId"
              label={translatedTextsObject.transactionId}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.dockey}>
            <CngTextField
              name="dockey"
              label={translatedTextsObject.dockey}
              disabled={disbaledCheck}
            />
          </CngGridItem>
          <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.retry}>
            <CngTextField
              name="retry"
              label={translatedTextsObject.retry}
              disabled={disabled}
            />
          </CngGridItem>
        </Grid>
      </CardContent>
    </Card>
  )
}

function toClientDataFormat(serverData) {
  let localData = DataFlattener.parse(serverData);
  localData.fileCreationDate = DateTimeFormatter.toClientDateTime(
    localData.fileCreationDate,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.startTime = DateTimeFormatter.toClientDateTime(
    localData.startTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.endTime = DateTimeFormatter.toClientDateTime(
    localData.endTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  return localData;
}

function toServerDataFormat(localData) {
  localData.fileCreationDate = DateTimeFormatter.toServerDateTime(
    localData.fileCreationDate,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.startTime = DateTimeFormatter.toServerDateTime(
    localData.startTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  localData.endTime = DateTimeFormatter.toServerDateTime(
    localData.endTime,
    'YYYY-MM-DD HH:mm:ss'
  );
  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
